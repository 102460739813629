define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/edit-topic/topic-custom-field-edit-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <TopicCustomFieldInput
    @fieldValue={{this.fieldValue}}
    @onChangeField={{this.onChangeField}}
  />
  */
  {
    "id": "+mm/gcgi",
    "block": "[[[8,[39,0],null,[[\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"fieldValue\"]],[30,0,[\"onChangeField\"]]]],null]],[],false,[\"topic-custom-field-input\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/edit-topic/topic-custom-field-edit-topic.hbs",
    "isStrictMode": false
  });
});