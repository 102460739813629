define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/edit-topic/topic-custom-field-edit-topic", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      6
   * title:       Show an input in topic title edit
   * description: If your field can be edited by the topic creator or
   *              staff, you may want to let them do this in the topic
   *              title edit view.
   * references:  app/assets/javascripts/discourse/app/templates/topic.hbs
   */

  class TopicCustomFieldEditTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    constructor() {
      super(...arguments);
      this.fieldValue = this.args.outletArgs.model.get(this.fieldName);
    }
    onChangeField(fieldValue) {
      this.args.outletArgs.buffered.set(this.fieldName, fieldValue);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onChangeField", [_object.action]))();
  }
  _exports.default = TopicCustomFieldEditTopic;
});