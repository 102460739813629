define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@glimmer/component", "@ember/controller", "@ember/object/computed", "@ember/service"], function (_exports, _component, _controller, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      8
   * title:       Display your field value in the topic
   * description: Display the value of your custom topic field below the
   *              title in the topic
   *              list.
   */

  class TopicCustomFieldTopicTitle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topic", [_controller.inject]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    get fieldValue() {
      return this.args.outletArgs.model.get(this.fieldName);
    }
  }
  _exports.default = TopicCustomFieldTopicTitle;
});