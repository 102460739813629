define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.topic.editingTopic}}
    <span>{{i18n
        "topic_custom_field.label"
        field=this.fieldName
        value=this.fieldValue
      }}</span>
  {{/unless}}
  */
  {
    "id": "EOMjOvEI",
    "block": "[[[41,[51,[30,0,[\"topic\",\"editingTopic\"]]],[[[1,\"  \"],[10,1],[12],[1,[28,[35,1],[\"topic_custom_field.label\"],[[\"field\",\"value\"],[[30,0,[\"fieldName\"]],[30,0,[\"fieldValue\"]]]]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/topic-title/topic-custom-field-topic-title.hbs",
    "isStrictMode": false
  });
});