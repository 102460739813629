define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/composer-fields/topic-custom-field-composer", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      5
   * title:       Show an input in the composer
   * description: If your field can be edited by users, you need to show an input in the composer.
   * references:  app/assets/javascripts/discourse/app/templates/composer.hbs
   */

  class TopicCustomFieldComposer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "composerModel", [(0, _computed.alias)("args.outletArgs.model")]))();
    #composerModel = (() => (dt7948.i(this, "composerModel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "topic", [(0, _computed.alias)("composerModel.topic")]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    constructor() {
      super(...arguments);

      // If the first post is being edited we need to pass our value from
      // the topic model to the composer model.
      if (!this.composerModel[this.fieldName] && this.topic && this.topic[this.fieldName]) {
        this.composerModel.set(this.fieldName, this.topic[this.fieldName]);
      }
      this.fieldValue = this.composerModel.get(this.fieldName);
    }
    onChangeField(fieldValue) {
      this.composerModel.set(this.fieldName, fieldValue);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeField", [_object.action]))();
  }
  _exports.default = TopicCustomFieldComposer;
});